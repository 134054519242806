import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import type { IUser } from 'utils/types';
import type { INotificationType } from '../components/NotificationModal';

export interface UserState {
  createUserDialogOpen: boolean;
  selectedUser: IUser | undefined;
  notificationModalOpen: boolean;
  notificationType: INotificationType;
  notificationForUser: string;
  userBlockingConfirmationOpen: boolean;
  userUnblockingConfirmationOpen: boolean;
}

const initialState: UserState = {
  createUserDialogOpen: false,
  selectedUser: undefined,
  notificationModalOpen: false,
  notificationType: 'UserCreated',
  notificationForUser: '',
  userBlockingConfirmationOpen: false,
  userUnblockingConfirmationOpen: false,
};

export const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    setCreateUserDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.createUserDialogOpen = action.payload;
    },
    setSelectedUser: (state, action: PayloadAction<IUser | undefined>) => {
      state.selectedUser = action.payload;
    },
    setNotificationModalOpen: (state, action: PayloadAction<boolean>) => {
      state.notificationModalOpen = action.payload;
    },
    setNotificationType: (state, action: PayloadAction<INotificationType>) => {
      state.notificationType = action.payload;
    },
    setNotificationForUser: (state, action: PayloadAction<string>) => {
      state.notificationForUser = action.payload;
    },
    setUserBlockingConfirmationOpen: (state, action: PayloadAction<boolean>) => {
      state.userBlockingConfirmationOpen = action.payload;
    },
    setUserUnblockingConfirmationOpen: (state, action: PayloadAction<boolean>) => {
      state.userUnblockingConfirmationOpen = action.payload;
    },
  },
});

export const {
  setCreateUserDialogOpen,
  setSelectedUser,
  setNotificationModalOpen,
  setNotificationType,
  setNotificationForUser,
  setUserBlockingConfirmationOpen,
  setUserUnblockingConfirmationOpen,
} = userManagementSlice.actions;

export const selectCreateUserDialogOpen = (state: RootState): boolean => state.userManagement.createUserDialogOpen;
export const selectSelectedUser = (state: RootState): IUser | undefined => state.userManagement.selectedUser;
export const selectNotificationModalOpen = (state: RootState): boolean => state.userManagement.notificationModalOpen;
export const selectNotificationType = (state: RootState): INotificationType => state.userManagement.notificationType;
export const selectNotificationForUser = (state: RootState): string => state.userManagement.notificationForUser;
export const selectUserBlockingConfirmationOpen = (state: RootState): boolean =>
  state.userManagement.userBlockingConfirmationOpen;
export const selectUserUnblockingConfirmationOpen = (state: RootState): boolean =>
  state.userManagement.userUnblockingConfirmationOpen;

export default userManagementSlice.reducer;
