import React from 'react';
import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { useGetRolesQuery } from 'app/apis';
import ExpandableBadgeContainer from './ExpandableBadgeContainer';
import { classNames } from 'utils/styleUtils';

export interface IRolesResponse {
  rolename: string;
  description: string;
  users: string[];
  adminaccess: boolean;
  status: string;
  componentaccess: string[];
}

interface SpendviewRolesTableProps {
  style: string;
}

const SpendviewRolesTable = (props: SpendviewRolesTableProps): JSX.Element => {
  const { data, isFetching } = useGetRolesQuery({});

  return (
    <div className={classNames(props.style, 'rounded-md overflow-hidden shadow-sm bg-white')}>
      <div className="sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
          <table className="w-full table-fixed divide-y divide-gray-300">
            <thead>
              <tr className="bg-dataops-dark divide-x divide-white flex">
                <th scope="col" className="w-2/12 py-3.5 pl-4 pr-3 mr-2 text-left text-sm text-white">
                  Role name
                </th>
                <th scope="col" className="w-3/12 px-3 py-3.5 text-left text-sm text-white">
                  Description
                </th>
                <th scope="col" className="w-3/12 px-3 py-3.5 text-left text-sm text-white">
                  Users
                </th>
                <th scope="col" className="w-1/12 px-3 py-3.5 text-left text-sm text-white">
                  Admin
                </th>
                <th scope="col" className="w-1/12 px-3 py-3.5 text-left text-sm text-white">
                  Status
                </th>
                <th scope="col" className="w-2/12 px-3 py-3.5 text-left text-sm text-white">
                  Component access
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {data?.map((role) => (
                <tr key={role.rolename} className="even:bg-gray-50 flex">
                  <td className="w-2/12 whitespace-nowrap px-3 py-5 text-sm">
                    <div className="text-gray-900 font-medium">{role.rolename}</div>
                  </td>
                  <td className="w-3/12 whitespace-pre-wrap px-3 py-5 text-sm">
                    <div className="text-gray-600">{role.description}</div>
                  </td>
                  <td className="w-3/12 px-3 py-5">
                    <ExpandableBadgeContainer badges={role.users} limit={3} />
                  </td>
                  <td className="w-1/12 px-3 py-5 text-sm flex justify-center">
                    {role.adminaccess ? (
                      <CheckCircleIcon className="h-5 w-5 text-green-600" aria-hidden="true" />
                    ) : (
                      <XCircleIcon className="h-5 w-5 text-red-600" aria-hidden="true" />
                    )}
                  </td>
                  <td className="w-1/12 whitespace-nowrap px-3 py-5 flex justify-center">
                    <div>
                      <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                        {role.status}
                      </span>
                    </div>
                  </td>
                  <td className="w-2/12 px-3 py-5">
                    <div className="flex flex-wrap items-start justify-start gap-1">
                      {role.componentaccess.map((componentAccess) => (
                        <div key={componentAccess}>
                          <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20">
                            {componentAccess}
                          </span>
                        </div>
                      ))}
                    </div>
                  </td>
                </tr>
              ))}
              {isFetching && (
                <tr className="even:bg-gray-50 flex animate-[pulse_1.5s_cubic-bezier(0.4,_0,_0.6,_0.1)_infinite]">
                  <td className="w-2/12 px-3 py-5">
                    <div className="h-3 bg-slate-300 rounded" />
                  </td>
                  <td className="w-3/12 px-3 py-5">
                    <div className="h-3 bg-slate-300 rounded" />
                  </td>
                  <td className="w-3/12 px-3 py-5">
                    <div className="h-3 bg-slate-300 rounded" />
                  </td>
                  <td className="w-1/12 px-3 py-5">
                    <div className="h-3 bg-slate-300 rounded" />
                  </td>
                  <td className="w-1/12 px-3 py-5">
                    <div className="h-3 bg-slate-300 rounded" />
                  </td>
                  <td className="w-2/12 px-3 py-5">
                    <div className="h-3 bg-slate-300 rounded" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {!isFetching && data?.length === 0 && <div className="m-4 text-sm text-gray-600">No roles found</div>}
        </div>
      </div>
    </div>
  );
};

export default SpendviewRolesTable;
