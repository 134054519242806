import React from 'react';
import { Outlet } from 'react-router-dom';
import UserManagementBar from 'features/user-management/components/UserManagementBar';
import CreateUserForm from 'features/user-management/components/CreateUserForm';
import NotificationModal from 'features/user-management/components/NotificationModal';
import UserBlockingConfirmationDialog from 'features/user-management/components/UserBlockingConfirmationDialog';
import UserUnblockingConfirmationDialog from 'features/user-management/components/UserUnblockingConfirmationDialog';

export default function UserManagementView(): JSX.Element {
  return (
    <>
      <div className="h-full flex-1">
        <UserManagementBar />
        <Outlet />
      </div>
      <CreateUserForm />
      <NotificationModal />
      <UserBlockingConfirmationDialog />
      <UserUnblockingConfirmationDialog />
    </>
  );
}
