import React, { Fragment } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { NavLink } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Button } from '@datalytyx/dataops-ui-library';
import AppSwitcher from './AppSwitcher';
import { classNames } from 'utils/styleUtils';

const AppBar = (): JSX.Element => {
  const { user, logout: auth0Logout } = useAuth0();

  const logout = (e: React.FormEvent): void => {
    auth0Logout({ logoutParams: { returnTo: window.location.origin } }).catch((e) => e);
  };

  return (
    <Disclosure as="nav" className="bg-dataops-dark h-16 w-screen fixed top-0 z-40">
      <div className="w-full h-full pl-[0.8rem] pr-2 sm:pr-4 lg:pr-8">
        <div className="w-full h-full relative flex items-center">
          <div className="flex-grow flex items-center px-2 lg:px-0">
            <AppSwitcher />
            <div className="flex-shrink-0 pl-6">
              <NavLink to="/">
                <img className="h-8 w-auto" src="/static/dataops-logo-with-tagline.svg" alt="DataOps.live" />
              </NavLink>
            </div>
            <div className="ml-6">
              <div className="flex items-center space-x-3">
                <div className="px-3 text-sm text-gray-100 font-semibold border-l border-r border-secondary-text">
                  Manage
                </div>
                <div className="px-3 text-sm text-gray-100 font-semibold border-secondary-text">
                  Spendview for Snowflake
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-1 px-2 ml-6 justify-end">
            <a target="_blank" rel="noreferrer" href="https://www.dataops.live/freetrial">
              <Button variant="marketing">Try DataOps.live</Button>
            </a>
          </div>
          <div className="ml-4">
            <div className="flex items-center">
              {/* Profile dropdown */}
              <Menu as="div" className="relative ml-4 flex-shrink-0">
                <div>
                  <Menu.Button className="flex rounded-full bg-gray-800 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="sr-only">Open user menu</span>
                    <div className="w-9 h-9 flex items-center justify-center rounded-full bg-dataops-primary-blue hover:bg-hover-primary-blue text-2xl font-medium text-gray-50">
                      {user?.name?.charAt(0).toUpperCase()}
                    </div>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 min-w-[12rem] origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="mx-4 py-2 text-gray-700 border-b-[1px]">
                      <div className="">
                        <div className="text-sm">{user?.name}</div>
                        <div className="text-xs text-gray-600">{user?.email}</div>
                      </div>
                    </div>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={logout}
                          className={classNames(
                            active ? 'bg-gray-100' : '',
                            'w-full block px-4 py-2 text-left text-sm text-gray-700',
                          )}
                        >
                          Sign out
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </Disclosure>
  );
};

export default AppBar;
