import React from 'react';
import OverviewCard from 'components/OverviewComponent';
import { useGetAverageUsersQuery } from 'app/apis';

export interface IAverageUsersResponse {
  total_users: number;
}

const AverageUsersCard = (): JSX.Element => {
  const { data, isFetching } = useGetAverageUsersQuery({});

  return (
    <div className="w-[12rem] h-[7rem] pb-4">
      <OverviewCard
        label="Active users"
        value={data?.total_users}
        isFetching={isFetching}
        tooltipText="Number of users logged in during the past 30 days"
      />
    </div>
  );
};

export default AverageUsersCard;
