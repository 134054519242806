import { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

export default function useAuthToken(): boolean {
  const [hasToken, setHasToken] = useState(false);
  const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently({
        authorizationParams: {
          audience: 'https://admin.dataops.live/customer_admin/',
        },
      })
        .then((token) => {
          axios.defaults.headers.common.Authorization = `Bearer ${token}`;
          setHasToken(true);
        })
        .catch((e) => {
          console.log(e);
          logout({ logoutParams: { returnTo: window.location.origin } }).catch((e) => e);
        });
    }
  }, [isAuthenticated]);

  return hasToken;
}
