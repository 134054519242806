import React from 'react';
import OverviewCard from 'components/OverviewComponent';
import { useGetTotalUsersQuery } from 'app/apis';

export interface ITotalUsersResponse {
  total_users: number;
  users_joined_last_30_days: number;
}

const TotalUsersCard = (): JSX.Element => {
  const { data, isFetching } = useGetTotalUsersQuery({});

  return (
    <div className="w-[12rem] h-[7rem] pb-4">
      <OverviewCard
        label="Total users"
        value={data?.total_users}
        secondaryValue={data?.users_joined_last_30_days}
        isFetching={isFetching}
        tooltipText="Total number of users and users joined in the last 30 days"
      />
    </div>
  );
};

export default TotalUsersCard;
