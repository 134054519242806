import React from 'react';
import SpendviewRolesTable from 'features/user-management/components/SpendviewRolesTable';

export default function UserManagementRolesTab(): JSX.Element {
  return (
    <div className="w-full mt-[3.2rem] mx-auto py-6 px-dashboard-side bg-dashboard-background">
      <SpendviewRolesTable style="w-full" />
    </div>
  );
}
