import React from 'react';
import TotalUsersCard from 'features/user-management/components/TotalUsersCard';
import AverageUsersCard from 'features/user-management/components/AverageUsersCard';
import SpendviewUsersTable from 'features/user-management/components/SpendviewUsersTable';

export default function UserManagementUsersTab(): JSX.Element {
  return (
    <div className="w-full mt-[3.2rem] mx-auto py-6 px-dashboard-side bg-dashboard-background">
      <div className="flex space-x-4">
        <TotalUsersCard />
        <AverageUsersCard />
      </div>
      <SpendviewUsersTable style="w-full" />
    </div>
  );
}
