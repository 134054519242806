import React from 'react';
import { Outlet } from 'react-router-dom';
import AppBar from 'components/AppBar';
import Sidebar from 'components/Sidebar';

export default function AdminView(): JSX.Element {
  return (
    <>
      <div className="min-h-full">
        <div className="w-full fixed top-0 z-40">
          <AppBar />
        </div>

        <div className="flex h-full flex-col mt-[3.9rem]">
          <div className="flex min-h-0 flex-1">
            <Sidebar />

            <main className="min-w-0 flex-1 border-t border-gray-200 lg:flex ml-16">
              <Outlet />
            </main>
          </div>
        </div>
      </div>
    </>
  );
}
