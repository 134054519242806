import React, { useState, useRef, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useBlockSpendviewUserMutation } from 'app/apis';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectUserBlockingConfirmationOpen,
  setUserBlockingConfirmationOpen,
  selectNotificationForUser,
} from '../reducers/userManagementSlice';

const UserBlockingConfirmationDialog = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const userBlockingDialogOpen = useAppSelector(selectUserBlockingConfirmationOpen);
  const email = useAppSelector(selectNotificationForUser);

  const [blockSpendviewUser] = useBlockSpendviewUserMutation();
  const [failed, setFailed] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const cancelButtonRef = useRef(null);

  const onBlockSpendviewUser = (): void => {
    setFailed(false);
    blockSpendviewUser({
      email,
    })
      .then((response: any) => {
        if (response.error !== undefined) {
          setErrorMessage(response.error.data ?? 'Failed to deactivate user');
          setFailed(true);
        } else {
          dispatch(setUserBlockingConfirmationOpen(false));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onClose = (): void => {
    dispatch(setUserBlockingConfirmationOpen(false));
    setFailed(false);
  };

  return (
    <Transition.Root show={userBlockingDialogOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => onClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all my-8 w-full max-w-md p-6">
                <div className="flex items-start">
                  <div className="flex flex-shrink-0 items-center justify-center rounded-full bg-red-100 mx-0 h-10 w-10">
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Block user
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are your sure you would like to block user <span className="text-gray-700">{email}</span>?
                      </p>
                    </div>
                  </div>
                </div>
                {failed && (
                  <div className="my-2 px-4 min-h-6 flex items-center justify-center">
                    <div className="text-sm w-full rounded bg-red-50 border-2 border-red-500 flex items-center justify-center">
                      <a className="w-full text-center font-medium text-red-600 hover:text-red-500 break-words p-1">
                        {errorMessage}
                      </a>
                    </div>
                  </div>
                )}
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-auto ml-3 justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500"
                    onClick={() => onBlockSpendviewUser()}
                  >
                    Block
                  </button>
                  <button
                    type="button"
                    className="inline-flex w-auto justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() => onClose()}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default UserBlockingConfirmationDialog;
