export function formatNumberLong(input: number, precision?: number): string {
  if (input === null) {
    return '0.0';
  }
  const formattedNumber = input.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: precision ?? (input < 1000 ? 1 : 0),
  });
  return formattedNumber;
}
