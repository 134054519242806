import React from 'react';
import { localPoint } from '@visx/event';
import { defaultStyles, useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { formatNumberLong } from 'utils/formatUtils';
import { classNames } from 'utils/styleUtils';

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#4b5563',
  color: 'white',
  fontSize: 12,
  zIndex: 30,
  maxWidth: '200px',
};

interface TooltipData {
  description: string;
}

interface OverviewCardProps {
  label: string;
  value: number | undefined | null;
  secondaryValue?: number;
  isFetching: boolean;
  tooltipText: string;
}

const OverviewCard = (props: OverviewCardProps): JSX.Element => {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  return (
    <div
      className={classNames(
        props.isFetching ? 'animate-[pulse_1.5s_cubic-bezier(0.4,_0,_0.6,_0.1)_infinite]' : '',
        'h-full py-4 px-8 flex-1 rounded-md shadow-sm bg-white space-y-1 xl:space-y-2 flex flex-col items-center justify-center text-center',
      )}
    >
      <div
        className="text-xs text-primary-text cursor-default"
        ref={containerRef}
        onMouseLeave={() => {
          hideTooltip();
        }}
        onMouseMove={(event) => {
          const eventSvgCoords = localPoint(event);
          showTooltip({
            tooltipData: { description: props.tooltipText },
            tooltipTop: eventSvgCoords?.y,
            tooltipLeft: eventSvgCoords?.x,
          });
        }}
      >
        {props.label}
      </div>
      {!props.isFetching && (
        <div className="flex items-center justify-center">
          <div className="text-primary-text text-2xl">
            {(props.value as number) < 0 ? '-' : ''}
            {props.value === undefined || props.value === null
              ? props.isFetching
                ? ''
                : 'N/A'
              : formatNumberLong(Math.abs(props.value))}
            {props.secondaryValue !== undefined && (
              <span
                className={`ml-1 text-base 2xl:text-base ${
                  (props.secondaryValue ?? 0) < 0 ? 'text-green-highlight' : 'text-gray-500'
                }`}
              >
                {formatNumberLong(props.secondaryValue)}
              </span>
            )}
          </div>
        </div>
      )}
      {props.isFetching && <div className="w-8 h-3 bg-slate-300 rounded" />}
      {tooltipOpen && tooltipData !== undefined && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div className="text-sm">{tooltipData.description}</div>
        </TooltipInPortal>
      )}
    </div>
  );
};

export default OverviewCard;
