import { createApi, retry } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import queryString from 'query-string';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import type { AxiosRequestConfig, AxiosError } from 'axios';
import type { IResource } from 'utils/types';
import type { ITotalUsersResponse } from 'features/user-management/components/TotalUsersCard';
import type { IAverageUsersResponse } from 'features/user-management/components/AverageUsersCard';
import type { IUsersByGroupResponse } from 'features/user-management/components/SpendviewUsersTable';
import type { IRolesResponse } from 'features/user-management/components/SpendviewRolesTable';
import type { ICreateUserRequestBody } from 'features/user-management/components/CreateUserForm';

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' },
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        paramsSerializer: {
          serialize: (params) => {
            return queryString.stringify(params);
          },
        },
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data ?? err.message,
        },
      };
    }
  };

export const adminApi = createApi({
  reducerPath: 'adminApi',
  baseQuery: retry(
    axiosBaseQuery({
      baseUrl: '/api/v1/customer_admin',
    }),
    {
      retryCondition: (e: any, __, { attempt }) => {
        if (e.originalStatus !== undefined && e.originalStatus >= 500 && e.originalStatus < 600 && attempt <= 3) {
          return true;
        }
        return false;
      },
    },
  ),
  keepUnusedDataFor: 600,
  tagTypes: ['Admin'],
  endpoints: (builder) => ({
    getTotalUsers: builder.query<ITotalUsersResponse, {}>({
      query: (params) => ({
        url: '/total_users',
        params: { ...params },
      }),
      providesTags: ['Admin'],
    }),
    getAverageUsers: builder.query<IAverageUsersResponse, {}>({
      query: (params) => ({
        url: '/user_activity',
        params: { ...params },
      }),
      providesTags: ['Admin'],
    }),
    getUsersByGroup: builder.query<{ items: IUsersByGroupResponse[]; count: number }, {}>({
      query: (params) => ({
        url: '/users_by_group',
        params: { ...params },
      }),
      providesTags: ['Admin'],
    }),
    getRoles: builder.query<IRolesResponse[], {}>({
      query: (params) => ({
        url: '/roles',
        params: { ...params },
      }),
      providesTags: ['Admin'],
    }),
    getResources: builder.query<{ items: IResource[]; count: number }, { resource_type: string }>({
      query: (params) => ({
        url: '/resources',
        params: { ...params },
      }),
      providesTags: ['Admin'],
    }),
    putSpendviewUser: builder.mutation<{}, ICreateUserRequestBody>({
      query: (data) => ({
        url: '/spendview_user',
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['Admin'],
    }),
    blockSpendviewUser: builder.mutation<{}, { email: string }>({
      query: (data) => ({
        url: '/block_spendview_user',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Admin'],
    }),
    unblockSpendviewUser: builder.mutation<{}, { email: string }>({
      query: (data) => ({
        url: '/unblock_spendview_user',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Admin'],
    }),
  }),
});

export const {
  useGetTotalUsersQuery,
  useGetAverageUsersQuery,
  useGetUsersByGroupQuery,
  useGetRolesQuery,
  useGetResourcesQuery,
  usePutSpendviewUserMutation,
  useBlockSpendviewUserMutation,
  useUnblockSpendviewUserMutation,
} = adminApi;
