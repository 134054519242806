import React from 'react';
import {
  XCircleIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  CheckCircleIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import { useGetUsersByGroupQuery } from 'app/apis';
import { useAppDispatch } from 'app/hooks';
import {
  setCreateUserDialogOpen,
  setSelectedUser,
  setUserBlockingConfirmationOpen,
  setUserUnblockingConfirmationOpen,
  setNotificationForUser,
} from '../reducers/userManagementSlice';
import type { IUserStatus } from 'utils/types';
import ExpandableBadgeContainer from './ExpandableBadgeContainer';
import { classNames } from 'utils/styleUtils';

interface ISnowflakeAccount {
  id: number;
  account: string;
}

export interface IUsersByGroupResponse {
  email: string;
  first_name: string;
  last_name: string;
  last_login: string;
  is_admin: boolean;
  status: IUserStatus;
  role: string;
  snowflake_accounts: ISnowflakeAccount[];
}

interface SpendviewUsersTableProps {
  style: string;
}

const SpendviewUsersTable = (props: SpendviewUsersTableProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { data, isFetching } = useGetUsersByGroupQuery({});

  return (
    <div className={classNames(props.style, 'rounded-md shadow-sm bg-white overflow-hidden')}>
      <div className="sm:-mx-6 lg:-mx-8">
        <div className="inline-block w-full sm:px-6 lg:px-8">
          <table className="w-full divide-y divide-gray-300 table-fixed">
            <thead>
              <tr className="bg-dataops-dark divide-x divide-white">
                <th scope="col" className="w-2/12 py-3 pl-4 pr-3 mr-2 text-left text-sm text-white">
                  Email
                </th>
                <th scope="col" className="w-2/12 px-3 py-3 text-left text-sm text-white">
                  Name
                </th>
                <th scope="col" className="w-1/12 px-3 py-3 text-left text-sm text-white">
                  Last login
                </th>
                <th scope="col" className="w-1/12 px-3 py-3 text-left text-sm text-white">
                  Is admin
                </th>
                <th scope="col" className="w-2/12 px-3 py-3 text-left text-sm text-white">
                  Role
                </th>
                <th scope="col" className="w-2/12 px-3 py-3 text-left text-sm text-white">
                  Spendview accounts
                </th>
                <th scope="col" className="w-1/12 px-3 py-3 text-left text-sm text-white">
                  Status
                </th>
                <th scope="col" className="w-1/12 px-3 py-3 text-left text-sm text-white"></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {!isFetching &&
                data?.items.map((user) => (
                  <tr key={user.email} className="even:bg-gray-50">
                    <td className="whitespace-nowrap px-3 py-3 text-sm align-top">
                      <div className="text-gray-900 truncate">{user.email}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-3 text-sm align-top">
                      <div className="text-gray-900 truncate">{`${user.first_name} ${user.last_name}`}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-3 text-sm align-top">
                      <div className="block wide:hidden text-gray-900 truncate">
                        {user.last_login !== null && new Date(user.last_login).toLocaleDateString('en-US')}
                      </div>
                      <div className="hidden wide:block text-gray-900 truncate">
                        {user.last_login !== null && new Date(user.last_login).toLocaleString('en-UK')}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-3 text-sm align-top">
                      <div className="w-full flex justify-center">
                        {user.is_admin ? (
                          <CheckCircleIcon className="h-5 w-5 text-green-600" aria-hidden="true" />
                        ) : (
                          <XCircleIcon className="h-5 w-5 text-red-600" aria-hidden="true" />
                        )}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500 truncate align-top">
                      {user.role}
                    </td>
                    <td className="whitespace-nowrap px-3 py-3 overflow-hidden">
                      <ExpandableBadgeContainer
                        badges={user.snowflake_accounts.map((snowflakeAccount) => snowflakeAccount.account)}
                        limit={1}
                      />
                      {user.snowflake_accounts.length === 0 && <div className="text-sm text-gray-500">No accounts</div>}
                    </td>
                    <td className="whitespace-nowrap px-3 py-3 overflow-hidden align-top">
                      <span
                        className={classNames(
                          user.status === 'Created'
                            ? 'bg-yellow-50 text-yellow-700 ring-yellow-600'
                            : user.status === 'Activated'
                            ? 'bg-green-50 text-green-700 ring-green-600'
                            : 'bg-red-50 text-red-700 ring-red-600',
                          'rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset',
                        )}
                      >
                        {user.status}
                      </span>
                    </td>
                    <td className="whitespace-nowrap px-3 py-3 text-sm align-top">
                      <div className="w-full flex justify-center space-x-2">
                        {user.status !== 'Deactivated' && (
                          <button
                            onClick={() => {
                              dispatch(
                                setSelectedUser({
                                  email: user.email,
                                  firstName: user.first_name,
                                  lastName: user.last_name,
                                  role: user.role,
                                  snowflakeAccounts: user.snowflake_accounts,
                                  status: user.status,
                                }),
                              );
                              dispatch(setCreateUserDialogOpen(true));
                            }}
                          >
                            <PencilSquareIcon
                              className="h-5 w-5 text-dataops-secondary-blue hover:text-hover-secondary-blue"
                              aria-hidden="true"
                            />
                          </button>
                        )}
                        {user.status === 'Deactivated' && (
                          <button
                            onClick={() => {
                              dispatch(setNotificationForUser(user.email));
                              dispatch(setUserUnblockingConfirmationOpen(true));
                            }}
                          >
                            <PlusCircleIcon
                              className="h-5 w-5 text-green-600 hover:text-green-400"
                              aria-hidden="true"
                            />
                          </button>
                        )}
                        {user.status !== 'Deactivated' && (
                          <button
                            onClick={() => {
                              dispatch(setNotificationForUser(user.email));
                              dispatch(setUserBlockingConfirmationOpen(true));
                            }}
                          >
                            <MinusCircleIcon className="h-5 w-5 text-red-600 hover:text-red-400" aria-hidden="true" />
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              {isFetching && (
                <tr className="even:bg-gray-50 bg-white animate-[pulse_1.5s_cubic-bezier(0.4,_0,_0.6,_0.1)_infinite]">
                  <td className="whitespace-nowrap px-3 py-3">
                    <div className="h-3 bg-slate-300 rounded" />
                  </td>
                  <td className="whitespace-nowrap px-3 py-3">
                    <div className="h-3 bg-slate-300 rounded" />
                  </td>
                  <td className="whitespace-nowrap px-3 py-3">
                    <div className="h-3 bg-slate-300 rounded" />
                  </td>
                  <td className="whitespace-nowrap px-3 py-3 ">
                    <div className="h-3 bg-slate-300 rounded" />
                  </td>
                  <td className="whitespace-nowrap px-3 py-3">
                    <div className="h-3 bg-slate-300 rounded" />
                  </td>
                  <td className="whitespace-nowrap px-3 py-3">
                    <div className="h-3 bg-slate-300 rounded" />
                  </td>
                  <td className="whitespace-nowrap px-3 py-3">
                    <div className="h-3 bg-slate-300 rounded" />
                  </td>
                  <td className="whitespace-nowrap px-3 py-3">
                    <div className="h-3 bg-slate-300 rounded" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {!isFetching && data?.count === 0 && <div className="m-4 text-sm text-gray-600">No users found</div>}
        </div>
      </div>
    </div>
  );
};

export default SpendviewUsersTable;
