import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { Button } from '@datalytyx/dataops-ui-library';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectNotificationModalOpen,
  selectNotificationType,
  selectNotificationForUser,
  setNotificationModalOpen,
} from '../reducers/userManagementSlice';

export type INotificationType = 'UserCreated' | 'UserUpdated';

export default function NotificationModal(): JSX.Element {
  const dispatch = useAppDispatch();
  const notificationModalOpen = useAppSelector(selectNotificationModalOpen);
  const notificationType = useAppSelector(selectNotificationType);
  const notificationForUser = useAppSelector(selectNotificationForUser);

  return (
    <Transition.Root show={notificationModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        onClose={() => {
          setNotificationModalOpen(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-5 text-center">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      {notificationType === 'UserCreated' && <>User {notificationForUser} created successfully</>}
                      {notificationType === 'UserUpdated' && <>User {notificationForUser} updated successfully</>}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {notificationType === 'UserCreated' && (
                          <>
                            The user is created with &quot;Created&quot; status and will receive an activation and
                            welcome email. Once the user activates their account, ther user status changes to
                            &quot;Activated&quot;. You have the option to trigger password reset or set it manually for
                            the user.
                          </>
                        )}
                        {notificationType === 'UserUpdated' && <>The user details have been successfully updated.</>}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 flex justify-center">
                  <Button onClick={() => dispatch(setNotificationModalOpen(false))}>Back to dashboard</Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
