import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingView from './LoadingView';

export default function LoginView(): JSX.Element {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        authorizationParams: {
          redirect_uri: `${window.location.origin}/react-callback`,
        },
      }).catch((e) => e);
    }
  }, [isLoading]);

  return <LoadingView />;
}
