import React, { useEffect, useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useGetResourcesQuery, usePutSpendviewUserMutation } from 'app/apis';
import DropdownSingleselect from 'components/DropdownSingleselect';
import AccountMultiselect from 'features/user-management/components/AccountMultiselect';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectCreateUserDialogOpen,
  selectSelectedUser,
  setCreateUserDialogOpen,
  setNotificationModalOpen,
  setNotificationType,
  setNotificationForUser,
} from '../reducers/userManagementSlice';
import type { IResource } from 'utils/types';

const roles = ['Spendview User', 'Spendview Owner'];

export interface ICreateUserRequestBody {
  email: string;
  first_name: string;
  last_name: string;
  role: string;
  snowflake_accounts: IResource[];
}

export default function CreateUserForm(): JSX.Element {
  const dispatch = useAppDispatch();
  const createUserDialogOpen = useAppSelector(selectCreateUserDialogOpen);
  const selectedUser = useAppSelector(selectSelectedUser);

  const { data, isFetching } = useGetResourcesQuery({
    resource_type: 'observability_rest.AccountCredential',
  });
  const [snowflakeAccounts, setSnowflakeAccounts] = useState<IResource[]>([]);
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState<string>(roles[0]);
  const [selectedSnowflakeAccounts, setSelectedSnowflakeAccounts] = useState<IResource[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [putSpendviewUser] = usePutSpendviewUserMutation();

  useEffect(() => {
    if (data !== undefined) {
      setSnowflakeAccounts(data?.items);
    }
  }, [data]);

  useEffect(() => {
    if (selectedUser !== undefined) {
      setEmail(selectedUser.email);
      setFirstName(selectedUser.firstName);
      setLastName(selectedUser.lastName);
      setSelectedRole(selectedUser.role);
      setSelectedSnowflakeAccounts(selectedUser.snowflakeAccounts);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (selectedRole === 'Spendview Owner') {
      setSelectedSnowflakeAccounts(snowflakeAccounts);
    } else {
      setSelectedSnowflakeAccounts([]);
    }
  }, [selectedRole]);

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    setLoading(true);
    setFailed(false);
    putSpendviewUser({
      email,
      first_name: firstName,
      last_name: lastName,
      role: selectedRole,
      snowflake_accounts: selectedSnowflakeAccounts,
    })
      .then((response: any) => {
        console.log(response);
        if (response.error !== undefined) {
          setLoading(false);
          if (response.error.status === 422 && response.error.data?.detail?.length !== 0) {
            console.log(response.error.data.detail[0].msg);
            setErrorMessage(response.error.data.detail[0].msg);
          } else if (response.error.status === 400) {
            setErrorMessage(response.error.data ?? 'Failed to create user');
          } else {
            setErrorMessage('Failed to create user');
          }
          setFailed(true);
        } else {
          dispatch(setCreateUserDialogOpen(false));
          dispatch(setNotificationForUser(email));
          dispatch(setNotificationType(selectedUser === undefined ? 'UserCreated' : 'UserUpdated'));
          dispatch(setNotificationModalOpen(true));
          onClose();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onClose = (): void => {
    dispatch(setCreateUserDialogOpen(false));
    setEmail('');
    setFirstName('');
    setLastName('');
    setSelectedRole(roles[0]);
    setSelectedSnowflakeAccounts([]);
    setLoading(false);
    setSuccess(false);
    setFailed(false);
    setErrorMessage('');
  };

  return (
    <Transition.Root show={createUserDialogOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={() => onClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center text-center items-start sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="mt-[8rem] relative transform rounded-lg bg-white px-4 pb-4 text-left shadow-xl transition-all md:max-w-md">
                <div className="bg-white rounded-md">
                  <div className="flex min-h-full items-center justify-center py-2 px-4 sm:px-6 lg:px-8">
                    <div className="w-full">
                      <div className="my-4 text-center text-lg font-bold tracking-tight text-gray-900">
                        {selectedUser === undefined ? 'Enter user details' : 'Edit user'}
                      </div>
                      <form className="space-y-4" onSubmit={handleSubmit}>
                        <input type="hidden" name="remember" defaultValue="true" />
                        <div>
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Business email address
                          </label>
                          <div className="mt-1">
                            <div className="flex rounded-md shadow-sm ring-1 ring-gray-300 text-sm">
                              <input
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus-visible:outline-gray-600"
                                placeholder="name@yourcompany.domain"
                                required
                              />
                            </div>
                          </div>
                        </div>

                        <div className="w-full flex">
                          <div className="w-1/2 pr-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900">First name</label>
                            <div className="mt-1">
                              <div className="flex rounded-md shadow-sm ring-1 ring-gray-300 text-sm">
                                <input
                                  type="text"
                                  value={firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus-visible:outline-gray-600"
                                  required
                                />
                              </div>
                            </div>
                          </div>

                          <div className="w-1/2 pl-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900">Last name</label>
                            <div className="mt-1">
                              <div className="flex rounded-md shadow-sm ring-1 ring-gray-300 text-sm">
                                <input
                                  type="text"
                                  value={lastName}
                                  onChange={(e) => setLastName(e.target.value)}
                                  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus-visible:outline-gray-600"
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <DropdownSingleselect options={roles} selected={selectedRole} setSelected={setSelectedRole} />

                        <AccountMultiselect
                          options={snowflakeAccounts}
                          value={selectedSnowflakeAccounts}
                          onChange={(e) => setSelectedSnowflakeAccounts(e)}
                          label="Snowflake accounts"
                          placeholder={
                            isFetching && data === undefined
                              ? 'Fetching accounts'
                              : snowflakeAccounts.length === 0
                              ? 'No snowflake accounts'
                              : 'Select accounts'
                          }
                          disabled={selectedRole === 'Spendview Owner'}
                        />

                        {loading && !failed && (
                          <div className="mt-1 min-h-6 flex items-center justify-center">
                            <div
                              className="animate-spin inline-block w-6 h-6 border-[2px] border-current border-t-transparent text-cyan-800 rounded-full"
                              role="status"
                              aria-label="loading"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        )}
                        {failed && (
                          <div className="mt-1 min-h-6 flex items-center justify-center">
                            <div className="text-sm w-full rounded bg-red-50 border-2 border-red-500 flex items-center justify-center">
                              <a className="w-full text-center font-medium text-red-600 hover:text-red-500 break-words p-1">
                                {errorMessage}
                              </a>
                            </div>
                          </div>
                        )}
                        {success && (
                          <div className="mt-1 min-h-6 flex items-center justify-center">
                            <div className="text-sm w-full rounded bg-green-50 border-2 border-green-500 flex items-center justify-center">
                              <a className="font-medium text-green-600 hover:text-green-500">Account added</a>
                            </div>
                          </div>
                        )}

                        <div className="w-full mt-[auto] px-4 flex space-x-4">
                          <button
                            onClick={() => onClose()}
                            className="group relative flex w-full justify-center rounded-md border border-transparent bg-dataops-secondary-blue hover:bg-hover-secondary-blue py-2 px-4 text-sm font-medium text-white focus:outline-none"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="group relative flex w-full justify-center rounded-md border border-transparent bg-dataops-secondary-blue hover:bg-hover-secondary-blue py-2 px-4 text-sm font-medium text-white focus:outline-none"
                          >
                            {selectedUser === undefined ? 'Save' : 'Edit'}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
