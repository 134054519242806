import React, { useState } from 'react';
import { classNames } from 'utils/styleUtils';

interface IExpandableBadgeContainerProps {
  badges: string[];
  limit: number;
}

const ExpandableBadgeContainer = (props: IExpandableBadgeContainerProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const visibleBadges = isOpen ? props.badges : props.badges.slice(0, props.limit);

  return (
    <div
      className={classNames(
        'cursor-pointer flex h-[auto] transition-[max-height] duration-[2000ms] overflow-hidden',
        isOpen ? 'max-h-[80rem]' : 'max-h-[9rem]',
      )}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className={classNames('flex flex-wrap items-start justify-start gap-1')}>
        {visibleBadges.map((badge) => (
          <div key={badge}>
            <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20">
              {badge}
            </span>
          </div>
        ))}
        {visibleBadges.length < props.badges.length && (
          <div className="ml-1 mt-[auto] leading-none text-gray-500 text-lg font-medium hover:text-gray-900">...</div>
        )}
      </div>
    </div>
  );
};

export default ExpandableBadgeContainer;
