import React, { useState, Fragment } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import type { IResource } from 'utils/types';
import { classNames } from 'utils/styleUtils';

interface IAccountMultiselectProps {
  options: IResource[];
  value: IResource[];
  onChange: (value: IResource[]) => void;
  label: string;
  placeholder: string;
  disabled?: boolean;
}

const AccountMultiselect = (props: IAccountMultiselectProps): JSX.Element => {
  const [query, setQuery] = useState('');

  const filteredOptions =
    query === ''
      ? props.options
      : props.options.filter((option) =>
          option.account.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')),
        );

  return (
    <div className="w-full">
      <Combobox value={props.value} onChange={props.onChange} by="id" multiple disabled={props.disabled}>
        {({ open }) => (
          <>
            <Combobox.Label
              className={classNames(
                props.disabled === true ? 'text-gray-400' : 'text-gray-900',
                'block text-sm font-medium leading-6',
              )}
            >
              {props.label}
            </Combobox.Label>
            <div className="relative mt-1">
              <div className="relative cursor-default overflow-hidden rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-base shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none sm:text-sm sm:leading-6">
                <Combobox.Button as="div" className="flex items-center pr-2 bg-white">
                  <Combobox.Input
                    key={open ? 'open' : 'closed'}
                    className="w-full text-black bg-transparent focus-within:outline-none"
                    displayValue={(options: IResource[]) => {
                      if (open) {
                        return '';
                      }
                      return (options ?? []).map((option: IResource) => option.account).join(', ');
                    }}
                    onChange={(event) => setQuery(event.target.value)}
                    placeholder={props.placeholder}
                  />
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Combobox.Button>
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery('')}
              >
                <Combobox.Options className="z-50 absolute mt-1 max-h-60 min-w-full max-w-[30rem] overflow-auto rounded-md bg-white pt-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {filteredOptions.length === 0 && query !== '' ? (
                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">Nothing found.</div>
                  ) : (
                    <>
                      {filteredOptions.map((option) => (
                        <Combobox.Option
                          key={option.id}
                          className={({ active }) =>
                            classNames(
                              active ? 'text-gray-900 bg-gray-100' : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9 text-sm',
                            )
                          }
                          value={option}
                          disabled={props.disabled}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}
                              >
                                {option.account}
                              </span>
                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-gray-900 bg-gray-100' : 'text-gray-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-2',
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                      {filteredOptions.length !== 0 && (
                        <div className="sticky bottom-0 w-full px-2 py-1 bg-white flex items-center justify-center">
                          <button
                            className={classNames(
                              'w-[8rem] py-1 text-center border border-gray-300 rounded-md text-xs',
                              props.disabled === true ? '' : 'hover:bg-gray-50',
                            )}
                            onClick={() => {
                              if (props.disabled !== true) props.onChange([]);
                            }}
                          >
                            Clear selection
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          </>
        )}
      </Combobox>
    </div>
  );
};

export default AccountMultiselect;
