import React from 'react';
import { NavLink } from 'react-router-dom';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { useAppDispatch } from 'app/hooks';
import { setCreateUserDialogOpen, setSelectedUser } from '../reducers/userManagementSlice';
import { classNames } from 'utils/styleUtils';

interface Tab {
  name: string;
  href: string;
  disabled: boolean;
}

const tabs: Tab[] = [
  { name: 'Users', href: '/user-management', disabled: false },
  { name: 'Groups', href: '/user-management/groups', disabled: true },
  { name: 'Roles', href: '/user-management/roles', disabled: false },
];

const UserManagementBar = (): JSX.Element => {
  const dispatch = useAppDispatch();

  return (
    <div className="fixed left-16 right-0 z-20 pl-8 pr-8 bg-white">
      <div className="border-b border-gray-200 flex items-center">
        <nav className="-mb-px flex space-x-8 flex-grow" aria-label="Tabs">
          {tabs.map((tab) => {
            return tab.disabled ? (
              <div
                key={tab.name}
                className="whitespace-nowrap py-4 px-1 border-b-2 font-[600] text-sm border-transparent text-gray-400 hover:text-gray-500 hover:border-gray-200 flex cursor-default"
              >
                <span className="mr-1">{tab.name}</span>
                <LockClosedIcon className="h-4 w-4" aria-hidden="true" />
              </div>
            ) : (
              <NavLink
                key={tab.name}
                to={tab.href}
                end
                className={({ isActive }) =>
                  classNames(
                    isActive
                      ? 'border-dataops-secondary-blue text-dataops-secondary-blue'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap py-4 px-1 border-b-2 font-[600] text-sm',
                  )
                }
              >
                {tab.name}
              </NavLink>
            );
          })}
        </nav>
        <button
          type="button"
          className="inline-flex items-center rounded-md border border-transparent bg-dataops-secondary-blue hover:bg-hover-secondary-blue px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none"
          onClick={() => {
            dispatch(setSelectedUser(undefined));
            dispatch(setCreateUserDialogOpen(true));
          }}
        >
          Add user
        </button>
      </div>
    </div>
  );
};

export default UserManagementBar;
